<template>
  <section v-if="item">
    <StrapiMarkdownComponent :item="item.attributes.entete">
    </StrapiMarkdownComponent>
    <template v-for="(bloc, index) in item.attributes.contenu">
      <component
        :key="index"
        :is="getComponent(bloc.__component)"
        :item="bloc"
      ></component>
    </template>
  </section>
</template>

<script>
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
import StrapiLigneComponent from "./StrapiLigneComponent.vue";
import StrapiCarrouselComponent from "./StrapiCarrouselComponent.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiEditoComponent",
  /**
   * societe = code de la société courante
   */
  props: ["societe"],
  components: {
    StrapiMarkdownComponent,
    StrapiLigneComponent,
    StrapiCarrouselComponent,
  },
  data() {
    return {
      item: null,
    };
  },
  mounted() {
    this.load();
    this.$watch(
      () => this.$route.path,
      () => this.load()
    );
    this.$watch(
      () => this.$route.params,
      () => this.load()
    );
  },
  methods: {
    getComponent(code) {
      return this.$store.getters["strapi/composant"](code)?.component;
    },
    load() {
      let url = this.$route.path;
      Object.keys(this.$route.params).forEach(
        (k) => (url = url.replace(`:${k}`, this.$route.params[k]))
      );
      return StrapiService.getEdito(url, this.societe).then(
        (response) => (this.item = response.data.data[0])
      );
    },
  },
};
</script>
