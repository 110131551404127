<template>
  <span>
    <span ref="anchor">
      <!-- Fichier encore sécurisé, pas de lien public -->
      <a v-if="locked" href="#" @click="unlock">
        <slot :locked="true">
          <b-button class="py-0 px-2" variant="link">
            <b-icon-file-earmark-lock2></b-icon-file-earmark-lock2>
            <slot name="label"></slot>
          </b-button>
        </slot>
      </a>
      <!-- Lien de téléchargement prêt à être utilisé -->
      <b-link v-else target="_blank" :href="href" @click="lock">
        <slot :locked="false">
          <b-button class="py-0 px-2" variant="link">
            <b-icon-file-earmark-pdf
              class="d-block d-md-none"
            ></b-icon-file-earmark-pdf>
            <slot name="label"></slot>
            <span class="d-none d-md-block">
              {{ downloadToken.extension.toUpperCase() }}
              <MontantComponent
                :value="downloadToken.tailleFichier / 1024"
              ></MontantComponent>
              ko
              <b-icon-download></b-icon-download>
            </span>
          </b-button>
        </slot>
      </b-link>
    </span>
    <b-tooltip
      :target="() => $refs['anchor']"
      triggers="hover"
      variant="primary"
    >
      <template v-if="locked">D&eacute;verrouiller le document</template>
      <template v-else>T&eacute;l&eacute;charger le document</template>
    </b-tooltip>
  </span>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
import ExploitationService from "../../services/exploitation.service";
export default {
  name: "DownloadComponent",
  components: { MontantComponent },
  props: ["typeDocument", "targetId"],
  data: () => ({
    downloadToken: null,
  }),
  computed: {
    locked() {
      return !this.downloadToken;
    },
    href() {
      if (this.locked) {
        return "";
      }
      let baseUrl = ExploitationService.getApiBaseUrl();
      return `${baseUrl}/download/${this.downloadToken.token}/${this.downloadToken.nomFichier}`;
    },
  },
  methods: {
    async unlock() {
      let response = await this.typeDocument.getDownloadToken(this.targetId);
      this.downloadToken = response.data;
    },
    async lock() {
      await this.$nextTick();
      this.downloadToken = null;
    },
  },
};
</script>
