<template>
  <b-container v-if="data" center class="carrousel-container">
    <b-carousel
      :id="`carrousel-${item.id}`"
      :interval="4000"
      controls
      indicators
    >
      <b-carousel-slide
        v-for="image in data"
        :key="image.id"
        :img-src="imageSrc(image)"
      ></b-carousel-slide>
    </b-carousel>
  </b-container>
</template>

<script>
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiCarrouselComponent",
  props: ["item"],
  methods: {
    data() {
      return this.item?.images?.data;
    },
    imageSrc(image) {
      return StrapiService.getAssetUrl(image.attributes.url);
    },
  },
};
</script>
