<template>
  <b-row
    :cols="item.mobileCols"
    :cols-md="item.tabletteCols"
    :cols-xl="item.ordinateurCols"
    :class="rowClasses"
    :align-v="item.alignV"
    :align-h="item.alignH"
  >
    <template v-for="(colonne, index) in item.colonnes">
      <b-col
        :key="index"
        :cols="colonne.mobileCols"
        :md="colonne.tabletteCols"
        :xl="colonne.ordinateurCols"
        :class="elementClasses(colonne)"
        :align-self="colonne.alignV"
      >
        <StrapiMarkdownComponent :item="colonne.contenu">
        </StrapiMarkdownComponent>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import StrapiMarkdownComponent from "./StrapiMarkdownComponent.vue";
const paramsCols = [
  { prop: "mobileCols", ifZero: "d-none", ifNotZero: "d-flex" },
  { prop: "tabletteCols", ifZero: "d-md-none", ifNotZero: "d-md-flex" },
  { prop: "ordinateurCols", ifZero: "d-xl-none", ifNotZero: "d-xl-flex" },
];
const paramsMarges = [
  { prop: "mobileMarginX", class: "mx" },
  { prop: "mobileMarginY", class: "my" },
  { prop: "tabletteMarginX", class: "mx-md" },
  { prop: "tabletteMarginY", class: "my-md" },
  { prop: "ordinateurMarginX", class: "mx-xl" },
  { prop: "ordinateurMarginY", class: "my-xl" },
  { prop: "mobilePaddingX", class: "px" },
  { prop: "mobilePaddingY", class: "py" },
  { prop: "tablettePaddingX", class: "px-md" },
  { prop: "tablettePaddingY", class: "py-md" },
  { prop: "ordinateurPaddingX", class: "px-xl" },
  { prop: "ordinateurPaddingY", class: "py-xl" },
];
export default {
  name: "StrapiLigneComponent",
  components: { StrapiMarkdownComponent },
  props: ["item"],
  computed: {
    rowClasses() {
      return this.elementClasses(this.item);
    },
  },
  methods: {
    elementClasses(item) {
      let classesCols = paramsCols.map((p) =>
        item[p.prop] === 0 ? p.ifZero : p.ifNotZero
      );
      let classesMarges = paramsMarges
        .filter((p) => item[p.prop] != null)
        .map((p) => `${p.class}-${item[p.prop]}`);
      return classesCols.concat(classesMarges).join(" ");
    },
  },
};
</script>
